import React from 'react'

export default function LastFooter() {
    return (
        <div>
           <div className="footer-body">
            <div className="footer-container" id='footer'>
                 <p>COPYRIGHT © 2024 JS Foods.</p>
            </div>
           </div> 
        </div>
    )
}
