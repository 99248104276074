import React from 'react'
import { useNavigate } from 'react-router-dom'
import png1 from '../img/pngicon1.png'
import png2 from '../img/pngicon2.png'

export default function AboutSection() {

    const navigation = useNavigate()

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    const handleNavLinkClick = () => {
        scrollToTop();
    };


    return (
        <div>
            <div className="about-body" id='Aboutus'>
                <div className="about-container">
                    <div className="anout-data">
                        <div className="abou-data-box">
                            {/* <h1>About Our <span>Company</span></h1>
                            <h2>Finest Products | Reliable Process</h2> */}
                            {/* <p>Welcome to JS Foods, your ultimate source for top-quality products! JS Foods, A  company, has been revolutionizing international trade with their extensive experience, expertise and quality services. With a commitment to quality, integrity, and customer satisfaction, they have built a trusted reputation in the market. JS Foods  providing top-notch services in the world of import-export!</p> */}
                            {/* <button onClick={() => { navigation('/MoreAbout'); handleNavLinkClick(); }} className='button1 see'>See More</button> */}

                            <h2>About Us</h2>
                            <p> Welcome to JS Foods, where quality meets innovation. Established with a passion for creating exceptional food products, JS Foods has grown to become a trusted name in Indonesia's vibrant food and beverage industry. Our journey is driven by a simple yet powerful mission: to bring joy to every meal and snack through our diverse and delicious offerings.</p>
                            {/* <button onClick={() => { navigation('/MoreAbout'); handleNavLinkClick(); }} className='button1 see'>See More</button> */}
                            <h2>Our Story</h2>
                            <p>From humble beginnings, JS Foods has evolved into a dynamic company that prides itself on its commitment to excellence. Our founders, inspired by Indonesia's rich culinary heritage, set out to craft products that resonate with the flavors and traditions of our culture while embracing modern tastes and global standards. Over the years, we have expanded our portfolio, ensuring that each product is crafted with the highest quality ingredients and utmost care.</p>
                            <h2>Our Commitment</h2>
                            <p>Quality and innovation are at the heart of everything we do. We continuously strive to improve our processes, embrace cutting-edge technology, and uphold the highest standards of food safety and sustainability. Our dedication to excellence ensures that every product bearing the JS Foods name is something we are proud to share with you.</p>
                        </div>

                    </div>



                    <div className="about-img-data">
                        <div className="about-img-conatiner">
                            <div className="about-img">
                                <div className="img-1">

                                </div>
                            </div>

                            <div className="abou-img-2">
                                <div className="img-2"></div>
                            </div>

                            <div className="abou-img-3">
                                <div className="img-3"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="pngIcon-box1">
                    <img src={png1}></img>
                </div>

                <div className="pngIcon-box2">
                    <img src={png2}></img>
                </div>

            </div>
        </div>
    )
}
